import React from "react";

const Service = () => (
  <div>
    <h1>Service</h1>
    <p>Feel free to reach out for inquiries.</p>
  </div>
);

export default Service;
