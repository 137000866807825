import React from "react";

const About = () => (
  <div>
    <h1>About Us</h1>
    <p>Learn more about our team and mission.</p>
  </div>
);

export default About;
