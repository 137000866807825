import React from "react";
import { Link } from "react-router-dom";
import "./Header.css"; // Assuming CSS for styles

const Header = () => (
  <header className="header-container">
    <div className="logo">ASTRATEK</div>
    <nav className="nav-menu">
      <Link to="/" className="nav-link">Home</Link>
      <Link to="/about" className="nav-link">About</Link>
      <Link to="/services" className="nav-link">Services</Link>
      <Link to="/contact" className="nav-link">Contact</Link>
    </nav>
    <button className="connect-button">Connect Now <span className="arrow">&#8594;</span></button>
  </header>
);

export default Header;