import React from "react";
import "./Home.css";
import "../constants/Colors.css";

const Home = () => (
  <div>
    {/* Hero Section */}
    <div className="hero-container">
      <div className="hero-text">
        <h1>
          fuel your <br />
          <span>Digital solutions to</span>
          <br />
          <span className="highlight">growth.</span>
        </h1>
        <p>
          Innovative solutions for technology firms and emerging businesses.
          <br />
          Fed up with the usual visual styles.
        </p>
      </div>
      <div className="hero-button">
        <button>
          CONNECT WITH US <span className="arrow">→</span>
        </button>
      </div>
    </div>

    {/* Additional Content (Existing Home Page Content) */}
    <div className="home-content">
      <h1>Welcome to My Website</h1>
      <p>This is the home page.</p>
    </div>
  </div>
);

export default Home;
